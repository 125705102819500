import useStyles from '../styles/landing/style';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Container,
  Box
  // Divider
} from '@material-ui/core';
import clsx from 'clsx';
import Layout from '../layouts/Main';
import LazyLoad from 'react-lazyload';
import { sibiEmmaBase64Img } from '../images/landingBase64';
import ReviewsSection from '../components/ReviewsSection';
import VideoPlayerRaw from '../components/VideoPlayerRaw';
import Link from 'next/link';
import Image from 'next/image';
import { gtmFireEvent } from '~/helpers/gtm';

import idea_to_video_img from '~/images/temporal_landing_imgs/idea_to_video.png';
import text_to_video_img from '~/images/temporal_landing_imgs/text_to_video.png';
import bulk_creator_img from '~/images/temporal_landing_imgs/bulk_creator.png';
import template_to_video_img from '~/images/temporal_landing_imgs/template_to_video.png';
import sheet_to_video_img from '~/images/temporal_landing_imgs/sheet_to_video.png';
import widgets_img from '~/images/temporal_landing_imgs/widgets.png';

const Landing = () => {
  const classes = useStyles();

  const handleImageRedirect = (url, trigger) => {
    window.open(url, '_blank');
    handleMixPanelEvent(url, trigger);
  };
  const handleMixPanelEvent = (url, trigger) => {
    gtmFireEvent({
      event: 'onButtonClick',
      category: 'homepage',
      action: `click-on-${trigger}`,
      label: `redirect-to-${url}`
    });
  };

  return (
    <Layout
      showTutorials={false}
      showLanguageMenu={false}
      showNotificationPanel={false}
      audience="video"
    >
      <Box className={classes.section}>
        {/* --- Section Header --- */}
        <Box className={classes.mainSection}>
          <Container style={{ maxWidth: 1024 }}>
            <Typography
              variant="h1"
              className={clsx(classes.textHeader, 'ta-center', 'mb-0')}
              sx={{ fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem', lg: '5.5rem' } }}
            >
              <span>{'Make Videos & Social Content.'}</span>
              <span className={classes.textGradient}>{' Faster'}</span>
            </Typography>
          </Container>
          <Container style={{ maxWidth: 1024 }}>
            <Box className={clsx(classes.textSlogan, 'ta-center', classes.hide_xs)}>
              <span>Are you spending too much time creating video and social content?</span>
            </Box>
            <Box className={clsx(classes.textSlogan, 'mt-5', 'ta-center', classes.hide_xs)}>
              <span>Don’t go anywhere, WOXO is about to give you back time!</span>
              <span></span>
            </Box>
            <Box className={clsx(classes.textSlogan, 'ta-center', classes.show_xs)}>
              <span>Are you spending too much time creating video and social content?</span>
              <span> Don’t go anywhere, WOXO is about to give you back time!</span>
            </Box>
            <Box className={classes.panelImageContainer}>
              <VideoPlayerRaw
                defaultHeight={535}
                lazyLoad={false}
                videoPreviewImage={sibiEmmaBase64Img}
                url={'https://vod.front10.cloud/landing/Benefits_of_Woxo_videos_2.mp4'}
              />
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.button}
                data-name="wt-header-btn"
                data-type="btn-click"
                href="/idea-to-video/app"
                onClick={() => handleMixPanelEvent('/idea-to-video/app', 'get-started')}
              >
                Get started
              </Button>
            </Box>
          </Container>
        </Box>
        {/* --- Section Header --- */}
        {/* // --- Section How It Works --- */}
        <Box className={classes.secondaryBg}>
          <Container className={classes.secondarySection} style={{ maxWidth: 1024 }}>
            <Typography variant="h1" className={classes.title}>
              Idea to Videos
            </Typography>
            <Box className={clsx(classes.container, 'cont-2-1')}>
              <LazyLoad height={300} once>
                <Image
                  className={classes.toolImage}
                  placeholder="blur"
                  onClick={() => handleImageRedirect('/idea-to-video/app', 'idea-to-video_img')}
                  alt="idea to video"
                  src={idea_to_video_img}
                  objectFit="cover"
                />
              </LazyLoad>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan)}>
                  <p>
                    <b>Do you want to turn your ideas into videos in minutes?</b> <br></br>
                    Enjoy a seamless content creation experience with <strong>
                      AI-generated
                    </strong>{' '}
                    scripts, media, hashtags, and premium voiceovers.
                  </p>

                  {/* <p>You’re all set.</p> */}
                </Box>
                <Box className={clsx(classes.buttonContainer, 'btn-left')}>
                  <Button
                    className={classes.button}
                    data-name="wt-header-btn"
                    data-type="btn-click"
                    href="/idea-to-video/app"
                    onClick={() => handleMixPanelEvent('/idea-to-video/app', 'idea-to-video_cta')}
                  >
                    Bring Your Ideas to Life
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        {/* // --- Section How It Works --- */}
        {/* --- Section 3: Meet Woxo Video Maker --- */}
        <Box className={classes.secondarySection} bgcolor={'#F9FBFD !important'}>
          <Box className={classes.removeStyles}>
            <Container style={{ maxWidth: 659 }}>
              <Typography variant="h2" className={classes.title}>
                Text To Videos
              </Typography>
              <Box className={classes.textSlogan}>
                <p>
                  <b>
                    Looking to master your content creation skills and sharing videos on a single
                    step?
                  </b>{' '}
                  Our AI-powered tool helps you create basic videos about your topic in just a few
                  clicks.
                </p>
              </Box>
            </Container>
            <Container>
              <Box className={classes.customButtonContainer}>
                <Button
                  className={classes.button}
                  data-name="wt-header-btn"
                  data-type="btn-click"
                  href="/text-to-video"
                  onClick={() => handleMixPanelEvent('/text-to-video', 'text-to-video_cta')}
                >
                  Experience Video Creation
                </Button>
              </Box>
              <Box
                className={classes.panelImageContainer}
                style={{
                  padding: '0 0 0 0',
                  margin: '0 0 0 0',
                  position: 'relative'
                }}
              >
                <LazyLoad height={300} once>
                  <Image
                    className={classes.toolImage}
                    placeholder="blur"
                    onClick={() => handleImageRedirect('/text-to-video', 'text-to-video_img')}
                    alt="text to video"
                    src={text_to_video_img}
                    objectFit="cover"
                  />
                </LazyLoad>
              </Box>

              {/* <Box className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  data-name="wt-section-3-btn"
                  data-type="btn-click"
                  href="/video/templates"
                >
                  Browse all templates
                </Button>
              </Box> */}
            </Container>
          </Box>
        </Box>
        {/* --- Section 3: Meet Woxo Video Maker --- */}
        {/* // --- Section Custom Branding and Calendar Ideas --- */}
        <Box className={clsx(classes.secondaryBg, classes.secondarySection)}>
          <Container style={{ maxWidth: 659 }}>
            <Typography variant="h1" className={classes.title}>
              Bulk Creator
            </Typography>
          </Container>
          <Container style={{ maxWidth: 1024 }}>
            <Box className={clsx(classes.container, 'cont-1-2')}>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan)}>
                  {/* <p>You can get inspired with fresh ideas for your content calendar.</p> */}

                  <p>
                    <b>
                      Are you tired of spending countless hours editing your content one by one?
                    </b>{' '}
                    &nbsp; With our easy-to-use interface, you can make changes to multiple files at
                    once, saving you hours of tedious work.
                  </p>
                </Box>
                <Box className={clsx(classes.buttonContainer, 'btn-right')}>
                  <Button
                    className={classes.button}
                    data-name="wt-header-btn"
                    data-type="btn-click"
                    href="/bulk-creator"
                    onClick={() => handleMixPanelEvent('/bulk-creator', 'bulk_creator_cta')}
                  >
                    Effortlessly Edit in Bulk
                  </Button>
                </Box>
              </Box>
              <Box className={clsx(classes.mainSectionImgBox, classes.order_1_xs)}>
                {/* <VideoPlayerRaw
                  defaultHeight={300}
                  loop={true}
                  videoPreviewImage={templatesBase64Img}
                  url={
                    'https://vod.front10.cloud/landing/Calendar_and_Branding_Screen_1280x720_1.mp4'
                  }
                /> */}
                <LazyLoad height={300} once>
                  <Image
                    className={classes.toolImage}
                    placeholder="blur"
                    onClick={() => handleImageRedirect('/bulk-creator', 'bulk_creator_img')}
                    alt="bulk creator"
                    src={bulk_creator_img}
                    objectFit="cover"
                  />
                </LazyLoad>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className={classes.secondaryBg}>
          <Container className={classes.secondarySection} style={{ maxWidth: 1024 }}>
            <Typography variant="h1" className={classes.title}>
              Template to Videos
            </Typography>
            <Box className={clsx(classes.container, 'cont-2-1')}>
              <LazyLoad height={300} once>
                <Image
                  className={classes.toolImage}
                  placeholder="blur"
                  onClick={() =>
                    handleImageRedirect('/sheet-to-video/templates', 'template_to_video_img')
                  }
                  alt="template to videos"
                  src={template_to_video_img}
                  objectFit="cover"
                />
              </LazyLoad>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan)}>
                  <p>
                    <b>
                      Looking for an easy way to create stunning videos without spending hours on
                      design and editing?
                    </b>{' '}
                    <br></br>
                    Simply select your template, add your content, and let our tool do the rest.
                  </p>
                </Box>
                <Box className={clsx(classes.buttonContainer, 'btn-left')}>
                  <Button
                    className={classes.button}
                    data-name="wt-header-btn"
                    data-type="btn-click"
                    href="/sheet-to-video/templates"
                    onClick={() =>
                      handleMixPanelEvent('/sheet-to-video/templates', 'templates_to_videos_cta')
                    }
                  >
                    Make Videos Quickly
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className={clsx(classes.secondaryBg, classes.secondarySection)}>
          <Container style={{ maxWidth: 659 }}>
            <Typography variant="h1" className={classes.title}>
              Sheet To Videos
            </Typography>
          </Container>
          <Container style={{ maxWidth: 1024 }}>
            <Box className={clsx(classes.container, 'cont-1-2')}>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan)}>
                  {/* <p>You can get inspired with fresh ideas for your content calendar.</p> */}
                  <p>
                    <b>
                      Have you ever wished you could turn your data-packed spreadsheets into a
                      visual story that engages and informs your audience?
                    </b>
                    &nbsp; Our tool allows you to transform your spreadsheets into captivating
                    videos that bring your data to life.
                  </p>
                </Box>
                <Box className={clsx(classes.buttonContainer, 'btn-right')}>
                  <Button
                    className={classes.button}
                    data-name="wt-header-btn"
                    data-type="btn-click"
                    href="/video/templates"
                    onClick={() => handleMixPanelEvent('/video/templates', 'sheet_to_videos_cta')}
                  >
                    Bring Data Alive
                  </Button>
                </Box>
              </Box>
              <Box className={clsx(classes.mainSectionImgBox, classes.order_1_xs)}>
                <LazyLoad height={300} once>
                  <Image
                    className={classes.toolImage}
                    placeholder="blur"
                    onClick={() => handleImageRedirect('/video/templates', 'sheet_to_video_img')}
                    alt="sheet to videos"
                    src={sheet_to_video_img}
                    objectFit="cover"
                  />
                </LazyLoad>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className={classes.secondaryBg}>
          <Container className={classes.secondarySection} style={{ maxWidth: 1024 }}>
            <Typography variant="h1" className={classes.title}>
              Widgets
            </Typography>
            <Box className={clsx(classes.container, 'cont-2-1')}>
              <LazyLoad height={300} once>
                <Image
                  className={classes.toolImage}
                  placeholder="blur"
                  onClick={() => handleImageRedirect('/instagram-widget', 'widgets_img')}
                  alt="widgets tools"
                  src={widgets_img}
                  objectFit="cover"
                />
              </LazyLoad>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan)}>
                  <p>
                    <b>
                      Want to keep your website visitors up-to-date with your social media activity
                      without them ever having to leave your site?
                    </b>{' '}
                    <br></br>
                    Widgets has got you covered!
                  </p>
                </Box>
                <Box className={clsx(classes.buttonContainer, 'btn-left')}>
                  <Button
                    className={classes.button}
                    data-name="wt-header-btn"
                    data-type="btn-click"
                    href="/instagram-widget"
                    onClick={() => handleMixPanelEvent('/instagram-widget', 'widgets_cta')}
                  >
                    Enhance Your Website
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <ReviewsSection />
        {/* // --- Section 5: Our Woxians say --- */}
        {/* --- Section 1: Companies --- */}
        <div className={classes.marqueeContainer}>
          <Box display="flex" justifyContent="center">
            <Typography variant="p" className={classes.marqueeText}>
              Proud to help companies, small businesses, influencers, and entrepreneurs across 147
              countries.
            </Typography>
          </Box>
          <Container>
            <div className={classes.marquee}>
              {/* <div className={classes.transparenceCover} /> */}
              <LazyLoad height={300}>
                <div item className={classes.marqueeDiv}>
                  <img
                    alt="apinchofkinder"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067103/videoLandingPage/new/1.png"
                  />
                  <img
                    alt="szk"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/8.png"
                  />
                  <img
                    alt="bissu"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/7.png"
                  />
                  <img
                    alt="hollagugu"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/2.png"
                  />
                  <img
                    alt="snapstore"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/3.png"
                  />
                  <img
                    alt="rytiri"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/4.png"
                  />
                  <img
                    alt="broadway"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/6.png"
                  />
                  <img
                    alt="rev"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/10.png"
                  />
                  <img
                    alt="citilink"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/9.png"
                  />
                </div>
              </LazyLoad>
            </div>
          </Container>
        </div>
        {/* // --- Section 7: Call to Action --- */}
        <Container className={classes.secondarySection}>
          <div className={classes.ctaContainer}>
            <LazyLoad height={300} offset={100}>
              <div className={clsx(classes.ctaContainer__fig_box)}>
                <img
                  alt="fig1"
                  src="https://res.cloudinary.com/dakp804eh/image/upload/v1615051496/videoLandingPage/vg-header-fig-1.svg"
                  className={classes.ctaContainer__fig_box__fig_1}
                />
                <img
                  alt="fig2"
                  src="https://res.cloudinary.com/dakp804eh/image/upload/v1621573279/woxo-landing-page/f2.svg"
                  className={classes.ctaContainer__fig_box__fig_2}
                />
              </div>
            </LazyLoad>
            <div className={classes.ctaInfo}>
              <Typography variant="h2" className={classes.subTitle}>
                Start Creating Videos Today
              </Typography>
              <Typography variant="h1" className={classes.title}>
                <span className={classes.textGradient}>Find the perfect plan for you!</span>
              </Typography>
              <Link
                href="/plans"
                className={classes.ctaLink}
                data-name="wt-see-all-plans-btn"
                data-type="btn-click"
              >
                View all plans
              </Link>
            </div>
          </div>
        </Container>
        {/* // --- Section 7: Call to Action --- */}
      </Box>
    </Layout>
  );
};

export default Landing;
